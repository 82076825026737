<template>
    <div>
        <v-app-bar
            flat
            color="transparent"
            dark
            fixed
            style="padding-top: 50px"
        >
            <v-toolbar-title class="ml-16" @click="toMain" style="position: fixed; top: 15px; left: 0">
                <v-img max-width="120" src="../../assets/logo.png"></v-img
            ></v-toolbar-title>

            <v-spacer></v-spacer>

            <div style="position: fixed; top: 10px; right: 0">
                <v-btn
                    text
                    class="font-weight-black"
                    @click="toMain"
                    style="font-size: 1.1vmax"
                >
                    首页
                </v-btn>

                <v-btn
                    text
                    class="ml-10 font-weight-black"
                    style="font-size: 1.1vmax"
                    @click="$router.push('/review')"
                >
                    2020回顾
                </v-btn>
            </div>
        </v-app-bar>

        <div class="content full-bg white--text">
            <h1 style="font-size: 2.5rem">2020精彩分享</h1>
            <span
                class="
                    overline
                    light-blue--text
                    text--lighten-3
                    block
                    text--disabled text-uppercase
                "
                style="font-size: 1.6rem !important"
            >
                2018 SHARED
            </span>

            <v-row v-for="(rowData, n) of newData" :key="n" class="mt-10">
                <v-col v-for="(item, m) in rowData" :key="m" cols="3">
                    <v-card
                        class="mx-auto white--text"
                        max-width="344"
                        color="light-blue darken-4"
                        @click="open(item.link)"
                    >
                        <v-img
                            :src="item.image_url"
                            height="200px"
                        ></v-img>
                        <div>
                            <v-card-title class="mb-5">
                                {{ item.title }}
                            </v-card-title>

                            <v-card-subtitle>
                                {{ item.desc }}
                            </v-card-subtitle>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Review',

    components: {},

    data: () => ({
        data: []
    }),

    computed: {
        newData () {
            let data = this.data
            let result = []
            for(let i=0,len=data.length;i<len;i+=4){
                result.push(data.slice(i,i+4))
            }
            return result
        }
    },

    mounted () {
        this.getData()
    },

    methods: {
        toMain() {
            window.location.href = '/'
        },
        open(url) {
            window.open(url, '_blank')
        },
        getData() {
            this.axios.get('api/post?type=review').then((response)=>{
                this.data = response.data
            }).catch((response)=>{
                console.log(response)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.menu {
    position: fixed;
    top: calc(50% - 256px);
    left: 8px;
    z-index: 200;
    background: transparent !important;

    .v-list {
        background: transparent !important;
    }
}

.content {
    padding: 256px 80px 200px 80px;
    padding-left: 80px;
}

.full-bg {
    background-image: url('../../assets/pc_bg2.jpg');
    background-size: 100% 100%;
}
</style>
